import React, { useState } from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import Bio from "../../component/TeamBio";

const TeamBio = (props: any) => {
  const [siteTitle, setSiteTitle] = useState("");
  const [siteDesc, setSiteDesc] = useState("");
  const pageData = {
    id: "matthew-calvin",
    titleTag: "Matthew Calvin | San Diego Referral Agent | The Selby Team",
    descTag: "Meet Matthew Calvin, a San Diego, California referral agent with The Selby Team.",
  };
  return (
    <LayoutWrapper
      title={pageData.titleTag}
      desc={pageData.descTag}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <Bio id={pageData.id} setSiteTitle={setSiteTitle} setSiteDesc={setSiteDesc} />
    </LayoutWrapper>
  );
};

export default TeamBio;
